import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { UpdateContext } from '../../content/context';
import Image from '../Image';
import { DoubleText } from '../../atoms/DoubleText';
import Modal from '../Modal';
import SubmitPush from '../AddContent/SubmitPush';
import { Menu } from '../../content/Icons';
import { getData, getIcon } from '../../utils/functions';
import { deleteVideo } from '../../utils/api';

import '../../content/dropdown.css';

export class VideoRow extends Component {
  static contextType = UpdateContext;
  constructor(props) {
    super(props);
    this.state = { isShowModal: false };
  }
  render() {
    return (
      <div>
        <div className='tableRow'>
          <div>
            <Image
              styleImg={{
                borderRadius: 4,
                objectFit: 'cover',
                border: '1px solid rgba(0,0,0,0.06)'
              }}
              img={this.props.value.imageUrl}
              width={85}
              height={48}
            />
          </div>

          <div className='secondary' style={{ width: 'calc(35% - 85px)' }}>
            <DoubleText
              text={this.props.value.title}
              desc={this.props.value.username}
            />
          </div>

          <div className='secondary' style={{ width: '15%' }}>
            <DoubleText
              text={this.props.value.authorFullName}
              desc={getData(this.props.value.publicationTime)}
            />
          </div>

          <div className='secondary' style={{ width: '10%' }}>
            <a
              href={this.props.value.sourceUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              {getIcon(this.props.value.sourceUrl)}
            </a>
          </div>

          <div className='secondary' style={{ width: '12.5%' }}>
            <span>{this.props.value.viewsCount}</span>
          </div>

          <div className='secondary' style={{ width: '12.5%' }}>
            <span>{this.props.value.listensCount}</span>
          </div>

          <div className='secondary' style={{ width: 'calc(15% - 80px)' }}>
            <div className='pushIndicator' data-push-status={this.props.value.pushStatus}>
              {this.props.value.pushStatus != null ? this.props.value.pushSuccessCount : ''}
            </div>
          </div>

          <div>
            <Modal isShowModal={this.state.isShowModal}>
              {
                <SubmitPush
                  value={{
                    postId: this.props.value.id,
                    topic: 'VIDEOS'
                  }}
                  hideModal={() => {
                    this.setState({ isShowModal: false });
                  }}
                />
              }
            </Modal>
            <DropdownButton
              style={{ width: 80, textAlign: 'center' }}
              alignRight
              title={<Menu />}
              id='dropdown-menu-align-right'
            >
              <Dropdown.Item
                onClick={() => {
                  this.setState({ isShowModal: true });
                }}
              >
                Отправить пуш
              </Dropdown.Item>
              <Dropdown.Item
                onClick={async () => {
                  await deleteVideo(this.props.value.id);
                  this.context.setState();
                }}
              >
                Удалить
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
    );
  }
}
