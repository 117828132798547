import React from 'react';

export function Input(props) {
  return (
    <div>
      { props.header ? <span class='inputHeader'>{props.header}</span> : ''}

      <input
        className   = 'input100'
        style       = {props.style}
        type        = {props.type ? props.type : 'text'}
        placeholder = {props.placeholder}
        value       = {props.state}
        onChange    = {props.onChange}
      />

      { props.description ? <span class='input_description'>{props.description}</span> : ''}
    </div>
  );
}

export function InputWithLength(props) {
  return (
    <div className='limitedInputWrapper'>
      <input
        className   = 'input100'
        type        = {props.type ? props.type : 'text'}
        placeholder = {props.placeholder}
        value       = {props.state}
        onChange    = {props.onChange}
        maxLength   = {props.maxLength}
      />

      <div className='inputCounter'>
        <p>{props.maxLength - props.state.length}</p>
      </div>
    </div>
  );
}

export function InputRadio(props) {
  return (
    <div className='radioInputWrapper'>
      <input
        type     = 'radio'
        id       = {props.id}
        value    = {props.value}
        checked  = {props.checked}
        onChange = {props.onChange}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  );
}
