import React from 'react';

import { ButtonLoader } from '../../atoms/Loader';
import { Input, InputWithLength } from '../../atoms/Input';

import { UploadWithPreview } from '../../components/Upload';

import {
  getParameterFromUrl,
  convertToDay,
  convertToTime
} from '../../utils/functions';
import { URL_FILE } from '../../content/const';

import {
  getCompetition,
  editCompetitionById,
  addCompetition,
  addImage
} from '../../utils/api';

var moment = require('moment');

export default class AddCompetition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: getParameterFromUrl('id'),
      file: null,
      imageUrl: '',
      nameFile: '',
      deadlineTime: '',
      deadlineDay: '',
      description: '',
      descriptor: '',
      name: '',
      resultStream: null,
      resultsTime: '',
      resultsDay: '',
      isFeatch: false,
      imageParam: null
    };
  }

  async componentDidMount() {
    if (this.state.id) {
      let data = await getCompetition(this.state.id);
      if (data.error) {
        this.setState({ error: true });
      } else {
        this.setState({
          imageUrl: data.imageUrl,
          nameFile: data.imageUrl.replace(`${URL_FILE}images/`, ''),
          deadlineTime: convertToTime(data.deadline),
          deadlineDay: convertToDay(data.deadline),
          description: data.description,
          descriptor: data.descriptor,
          name: data.name,
          resultStream: data.resultStream === '' ? null : data.resultStream,
          resultsTime: convertToTime(data.results),
          resultsDay: convertToDay(data.results)
        });
      }
    }
  }

  addOrUpdateCompetition = async () => {
    this.setState({ isFeatch: true });
    var img = new Image();
    if (!this.state.id) {
      let nameImage = await addImage(this.state.file);

      img.src = URL_FILE + nameImage.fileName;
      img.onload = () => {
        this.setState(
          {
            imageParam: { height: img.naturalHeight, width: img.naturalWidth }
          },
          async () => {
            let data = await addCompetition({
              deadline: moment(
                this.state.deadlineDay + ' ' + this.state.deadlineTime
              ).unix(),
              description: this.state.description,
              descriptor: this.state.descriptor,
              imageUrl: URL_FILE + nameImage.fileName,
              imageWidth: this.state.imageParam.width,
              imageHigh: this.state.imageParam.height,
              name: this.state.name,
              resultStream: this.state.resultStream,
              results: moment(
                this.state.resultsDay + ' ' + this.state.resultsTime
              ).unix()
            });
            if (data.error) {
              this.setState({ error: true, isFeatch: false });
            } else {
              this.setState({
                id: data.id,
                isFeatch: false
              });
              document.location.href = `/competition/?id=${this.state.id}`;
            }
          }
        );
      };
    } else {
      let nameImage = {};
      if ((this.state.file === null) & (this.state.id !== null)) {
        nameImage = { fileName: this.state.imageUrl };
      } else {
        nameImage = await addImage(this.state.file);
      }

      if (nameImage.fileName.indexOf('https') !== -1) {
        img.src = nameImage.fileName;
      } else {
        img.src = URL_FILE + nameImage.fileName;
      }

      img.onload = () => {
        this.setState(
          {
            imageParam: { height: img.naturalHeight, width: img.naturalWidth }
          },
          async () => {
            let data = await editCompetitionById(this.state.id, {
              deadline: moment(
                this.state.deadlineDay + ' ' + this.state.deadlineTime
              ).unix(),
              description: this.state.description,
              descriptor: this.state.descriptor,
              imageUrl:
                nameImage.fileName.indexOf('https') !== -1
                  ? nameImage.fileName
                  : URL_FILE + nameImage.fileName,
              imageWidth: this.state.imageParam.width,
              imageHigh: this.state.imageParam.height,
              name: this.state.name,
              resultStream: this.state.resultStream,
              results: moment(
                this.state.resultsDay + ' ' + this.state.resultsTime
              ).unix()
            });
            if (data.error) {
              this.setState({ error: true, isFeatch: false });
            } else {
              this.setState({
                id: data.id,
                isFeatch: true
              });
              document.location.href = `/competition/?id=${this.state.id}`;
            }
          }
        );
      };
    }
  };

  submitEnabled = () => {
    const { descriptor, name, description, deadlineDay, deadlineTime, resultsDay, resultsTime, imageUrl, file } = this.state

    return (
      (!!file || !!imageUrl)
      && !!descriptor
      && !!name
      && !!description
      && !!deadlineDay
      && !!deadlineTime
      && !!resultsDay
      && !!resultsTime
    )
  };

  render() {
    const isCreateForm = !this.state.id;

    const title =
      isCreateForm ? 'Создание конкурса' : 'Редактирование конкурса';
    const submitButtonTitle =
      isCreateForm ? 'Создать и продолжить' : 'Сохранить изменения';

    return (
      <div className='fullWidthForm'>
        <div>
          <div className='header'>
            <h1>{title}</h1>
          </div>

          <div className='formBlock'>
            <h4 style={{marginBottom: 16}}>Расскажите о конкурсе*</h4>

            <div
              style = {{
                display: 'grid',
                gridTemplateColumns: '48.5% 48.5%',
                gridColumnGap: '3%',
              }}
            >
              <InputWithLength
                state       = {this.state.descriptor}
                onChange    = {(event) => this.setState({ descriptor: event.target.value })}
                placeholder = {'Дескриптор'}
                maxLength   = { 30 }
              />

              <InputWithLength
                state       = {this.state.name}
                onChange    = {(event) => this.setState({ name: event.target.value })}
                placeholder = {'Название'}
                maxLength   = { 50 }
              />
            </div>

            <div style={{ paddingTop: 16 }}>
              <textarea
                placeholder={'Описание'}
                className='input100'
                value={this.state.description}
                onChange={event => {
                  this.setState({ description: event.target.value });
                }}
              />
            </div>
          </div>

          <div className='formBlock'>
            <h4>Загрузите обложку*</h4>
            <p className='formBlockDescription'>
              Минимальный размер 1005×735px
            </p>

            <div>
              <UploadWithPreview
                value={{
                  imageUrl: this.state.imageUrl,
                  setFile: newFile => {
                    this.setState({
                      file: newFile
                    });
                  },
                  deleteFile: () => {
                    this.setState({ file: null, imageUrl: '' });
                  }
                }}
                text={'Выбор фото'}
                type={'image/*'}
              />
            </div>
          </div>

          <div className='formBlock'>
            <h4>Укажите дедлайн*</h4>
            <p className='formBlockDescription'>
              Дата, до которой будут приниматься заявки
            </p>

            <div
              style = {{
                display: 'grid',
                gridTemplateColumns: '32% 16%',
                gridColumnGap: 16,
              }}
            >
              <Input
                state       = {this.state.deadlineDay}
                onChange    = {(event) => this.setState({ deadlineDay: event.target.value })}
                type        = {'date'}
                placeholder = {'DD.MM.YYYY'}
              />

              <Input
                state       = {this.state.deadlineTime}
                onChange    = {(event) => this.setState({ deadlineTime: event.target.value })}
                type        = {'time'}
                placeholder = {'21:00'}
              />
            </div>
          </div>

          <div className='formBlock'>
            <h4>Подведение итогов*</h4>
            <p className='formBlockDescription'>
              Укажите дату подведения итогов и ссылку YouTube на стрим
            </p>

            <div
              style = {{
                display: 'grid',
                gridTemplateColumns: '32% 16% 50%',
                gridColumnGap: 16,
              }}
            >
              <Input
                state       = {this.state.resultsDay}
                onChange    = {(event) => this.setState({ resultsDay: event.target.value })}
                type        = {'date'}
                placeholder = {'DD.MM.YYYY'}
              />

              <Input
                state       = {this.state.resultsTime}
                onChange    = {(event) => this.setState({ resultsTime: event.target.value })}
                type        = {'time'}
                placeholder = {'21:00'}
              />

              <Input
                state       = {this.state.resultStream}
                onChange    = {(event) => this.setState({ resultStream: event.target.value })}
                placeholder = {'Ссылка на стрим'}
              />
            </div>
          </div>
        </div>

        <div className='submitWrapper'>
          <div>
            <p className='button_description'>
              Конкурс не будет опубликован.<br></br>Вы сможете добавить призы, условия и
              создать форму для участия.
            </p>
            <div onClick={() => { this.addOrUpdateCompetition(); }}>
              <p className={`large_button ${this.submitEnabled() ? 'active' : 'disable'}`}>
                {this.state.isFeatch ? (<ButtonLoader/>) : (submitButtonTitle)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
