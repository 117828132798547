import React from 'react';

export function DoubleText(props) {
  return (
    <div>
      <span>{props.text}</span>
      <span className='subLabel'>{props.desc}</span>
    </div>
  );
}
