import cookie from "react-cookies";
import { getError } from "./functions";
import { URL_HOST } from "../content/const";

const URL_ENV = `${URL_HOST}/services/rest/`;
const LOGIN_URL = `${URL_HOST}/services/login`;
// const URL_ENV = `/wylsa-admin/api/`;
// const LOGIN_URL = `/wylsa-admin/api/auth/login`;
const URL = URL_ENV;

const buildHeaders = (token, method, isFile = false) => {
  let headers = {
    Accept: "application/json",
    "cache-control": "no-cache",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": method
  };
  if (!isFile) {
    headers = {
      ...headers,
      "Content-Type": "application/json"
    };
  }

  if (!token) {
    return headers;
  }

  return {
    ...headers,
    Authorization: token
  };
};

export const buildBody = data => {
  if (!data) {
    return null;
  }

  let formBody = [];

  for (let property in data) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  return formBody.join("&");
};

function apiRequestSendFile(method, url, data, token = null) {
  var formData = new FormData();

  for (var name in data) {
    formData.append(name, data[name]);
  }

  return fetch(url, {
    method,
    headers: buildHeaders(token, method, true),
    body: formData
  })
    .then(function(response) {
      if (response.status !== 200) {
        return {
          error: response.status,
          message: `Ошибка ${response.status} - ${response.statusText}`
        };
      }
      return Promise.resolve(response);
    })
    .then(res => {
      return res.json();
    })
    .then(function(data) {
      return data;
    })
    .catch(function(error) {
      return { error: true, message: error };
    });
}

const apiRequestWithBody = async (method, url, data, token = null) => {
  return await fetch(url, {
    method,
    headers: buildHeaders(token, method),
    body: JSON.stringify(data)
  })
    .then(function(response) {
      if (response.status !== 200) {
        return {
          error: true,
          code: response.status,
          message: getError(response.status)
        };
      } else {
        return Promise.resolve(response);
      }
    })
    .then(res => {
      if (res.error) {
        return res;
      } else {
        return res.json();
      }
    })
    .then(function(data) {
      return data;
    });
  // .catch(response => {
  //   return { error: true, message: response };
  // });
};

const apiRequestImg = async (method, url, token = null) => {
  return await fetch(url, {
    method,
    headers: buildHeaders(token, method)
  })
    .then(function(response) {
      if (response.status !== 200) {
        return {
          error: response.status,
          message: `Ошибка ${response.status} - ${response.statusText}`
        };
      } else {
        return Promise.resolve(response);
      }
    })
    .then(res => res.blob())
    .catch(function(error) {
      return { error: true, message: error };
    });
};

const apiRequest = async (method, url, token = null) => {
  return await fetch(url, {
    method,
    headers: buildHeaders(token, method)
  })
    .then(function(response) {
      if (response.status !== 200) {
        return {
          error: response.status,
          message: `Ошибка ${response.status} - ${response.statusText}`
        };
      } else {
        return Promise.resolve(response);
      }
    })
    .then(res => res.json())
    .then(function(data) {
      return data;
    })
    .catch(function(error) {
      return { error: true, message: error };
    });
};

const apiRequestNoAnswer = async (method, url, token = null) => {
  await fetch(url, {
    method,
    headers: buildHeaders(token, method)
  }).then(function(response) {
    if (response.status !== 200) {
      return {
        error: response.status,
        message: `Ошибка ${response.status} - ${response.statusText}`
      };
    } else {
      return {
        message: `Ok`
      };
    }
  });
};

const apiRequestNoAnswerWithBody = async (method, url, data, token = null) => {
  await fetch(url, {
    method,
    headers: buildHeaders(token, method),
    body: JSON.stringify(data)
  })
    .then(function(response) {
      if (response.status !== 200) {
        return {
          error: response.status,
          message: `Ошибка ${response.status} - ${response.statusText}`
        };
      } else {
        return { error: false };
      }
    })
    .then(res => res);
};

// Authentication Controller
export const login = async data => {
  const datas = await apiRequestWithBody("POST", LOGIN_URL, data);
  if (datas.error) {
    return datas;
  }

  const profile = await apiRequest(
    "GET",
    `${URL}admin-users/current`,
    datas.accessToken
  );

  cookie.save(
    "token",
    { token: datas.accessToken, profile: profile },
    { path: "/" }
  );

  return { error: false };
};

export const getURLToResetPassword = async data => {
  return await apiRequestWithBody("POST", `${URL}public/reset-password`, data);
};

export const getUserData = async token => {
  return await apiRequest("GET", `${URL}public/reset-password/${token}`);
};

export const resetPassword = async (token, data) => {
  let datas = await apiRequestNoAnswerWithBody(
    "PUT",
    `${URL}public/reset-password/${token}`,
    data
  );
  return datas;
};

//Feed Controller
export const getFeedList = page => {
  return apiRequest(
    "GET",
    `${URL}feed?page=${page}`,
    cookie.load("token").token
  );
};

export const feedCreate = data =>
  apiRequestWithBody("POST", `${URL}feed`, data, cookie.load("token").token);

export const feedById = id =>
  apiRequest("GET", `${URL}feed/${id}`, cookie.load("token").token); // - пока не знаю как делать запрос

export const getLiveStreamById = id =>
  apiRequest("GET", `${URL}liveStream/${id}`, cookie.load("token").token);

export const feedUpdateById = (id, data) =>
  apiRequestWithBody(
    "PUT",
    `${URL}feed/${id}`,
    data,
    cookie.load("token").token
  );

export const feedPublish = id =>
  apiRequest("PUT", `${URL}feed/publish/${id}`, cookie.load("token").token);

export const deleteFeed = id =>
  apiRequestNoAnswer("DELETE", `${URL}feed/${id}`, cookie.load("token").token);

export const getWPPostsList = page => {
  return apiRequest(
    "GET",
    `${URL}wp-posts/list?page=${page}`,
    cookie.load("token").token
  );
};

// Instagram Post Controller
export const instagramPostAdd = async data => {
  const datas = await apiRequest(
    "POST",
    `${URL}instagramPost?postUrl=${data}`,
    cookie.load("token").token
  );
  if (datas.error) {
    return {
      error: true,
      message: `Что-то пошло не так... Вот сообщение: ${datas.error}`
    };
  }
  return { error: false };
};

export const instagramPostDelete = async data => {
  await apiRequest(
    "DELETE",
    `${URL}instagramPost?sourceUrl=${data}`,
    cookie.load("token").token
  );
};

export const getLiveStreamList = async page => {
  return await apiRequest(
    "GET",
    `${URL}liveStream/list?page=${page}&statuses=DRAFT,PLANNED,LIVE,FINISHED,ARCHIVE`,
    cookie.load("token").token
  )
};

// Social Network Controller
export const getPostsList = async page => {
  return await apiRequest(
    "GET",
    `${URL}social-network?page=${page}`,
    cookie.load("token").token
  );
};

// Admin Users Controller
export const getUsers = () => {
  return apiRequest("GET", `${URL}admin-users`, cookie.load("token").token);
};

export const getUsersByID = id => {
  return apiRequest(
    "GET",
    `${URL}admin-users/${id}`,
    cookie.load("token").token
  );
};

export const updateUser = async (id, data) => {
  const datas = await apiRequestWithBody(
    "PUT",
    `${URL}admin-users/${id}`,
    data,
    cookie.load("token").token
  );
  return datas;
};

export const createUser = async (token, data) => {
  const datas = await apiRequestWithBody(
    "POST",
    `${URL}admin-users/public/${token}`,
    data
  );
  return datas;
};

// Video Controller
export const getVideoList = async page => {
  return await apiRequest(
    "GET",
    `${URL}videos?page=${page}`,
    cookie.load("token").token
  );
};

export const deleteVideo = id => {
  apiRequestNoAnswer(
    "DELETE",
    `${URL}videos/${id}`,
    cookie.load("token").token
  );
};

export const getPlaylistVideo = (page, id) => {
  return apiRequest(
    "GET",
    `${URL}videos?page=${page}&videoPlayListId=${id}`,
    cookie.load("token").token
  );
};

export const addVideoByURL = async data => {
  const datas = await apiRequestWithBody(
    "POST",
    `${URL}videos`,
    { videoUrl: data },
    cookie.load("token").token
  );
  if (datas.error) {
    return {
      error: true,
      message: `Что-то пошло не так... Вот сообщение: ${datas.error}`
    };
  }
  return { error: false };
};

export const addVideoFromS3 = async data => {
  const datas = await apiRequestWithBody(
    "POST",
    `${URL}videos/self-hosted`,
    { ...data },
    cookie.load("token").token
  );
  if (datas.error) {
    return {
      error: true,
      message: `Что-то пошло не так... Вот сообщение: ${datas.error}`
    };
  }
  return { error: false };
};

export const getChannels = page => {
  return apiRequest(
    "GET",
    `${URL}videos/channels?page=${page}`,
    cookie.load("token").token
  );
};

export const addChannels = data =>
  apiRequestWithBody(
    "POST",
    `${URL}videos/channels`,
    { videoChannelUrl: data },
    cookie.load("token").token
  );

export const activateVideoChannel = async videoChannelId => {
  apiRequestNoAnswer(
    "PUT",
    `${URL}videos/channels/activate/${videoChannelId}`,
    cookie.load("token").token
  );
};

export const deactivateVideoChannel = async videoChannelId => {
  apiRequestNoAnswer(
    "PUT",
    `${URL}videos/channels/deactivate/${videoChannelId}`,
    cookie.load("token").token
  );
};

export const getPlaylists = () => {
  return apiRequest(
    "GET",
    `${URL}videos/playlists`,
    cookie.load("token").token
  );
};

export const addPlaylists = data =>
  apiRequestWithBody(
    "POST",
    `${URL}videos/playlists`,
    data,
    cookie.load("token").token
  );

export const deactivateVideoPlaylist = id => {
  apiRequestNoAnswer(
    "PUT",
    `${URL}videos/playlists/deactivate/${id}`,
    cookie.load("token").token
  );
};

export const activateVideoPlaylist = id => {
  apiRequestNoAnswer(
    "PUT",
    `${URL}videos/playlists/activate/${id}`,
    cookie.load("token").token
  );
};

export const updatePlaylist = async (id, data) => {
  return await apiRequestWithBody(
    "PUT",
    `${URL}videos/playlists/${id}`,
    data,
    cookie.load("token").token
  );
};

//Media File Controller
export const getFile = filename =>
  apiRequestImg("GET", `${URL}media/${filename}`, cookie.load("token").token);

export const addImage = file =>
  apiRequestSendFile(
    "POST",
    `${URL}media/image`,
    { file },
    cookie.load("token").token
  );

export const addMP3 = file =>
  apiRequestSendFile(
    "POST",
    `${URL}media/mp3`,
    { file },
    cookie.load("token").token
  );

// Comment Controller
export const getComments = (page = 0, search = "") => {
  return search !== ""
    ? apiRequest(
        "GET",
        `${URL}comment/all?page=${page}&search=${search}`,
        cookie.load("token").token
      )
    : apiRequest(
        "GET",
        `${URL}comment/all?page=${page}`,
        cookie.load("token").token
      );
};

export const getDeletedComments = page =>
  apiRequest(
    "GET",
    `${URL}comment/deleted?page=${page}`,
    cookie.load("token").token
  );

export const getPublishedComments = page =>
  apiRequest(
    "GET",
    `${URL}comment/published?page=${page}`,
    cookie.load("token").token
  );

export const deleteComment = id =>
  apiRequest(
    "DELETE",
    `${URL}comment?commentId=${id}`,
    cookie.load("token").token
  );

export const getReportedComments = page =>
  apiRequest(
    "GET",
    `${URL}comment/reported?page=${page}`,
    cookie.load("token").token
  );

export const removeReport = async id => {
  return await apiRequestWithBody(
    "PUT",
    `${URL}comment/removeReport?commentId=${id}`,
    cookie.load("token").token
  );
};

// Users
export const getAllUsers = (page = 0, search = "", banOnly = false) =>
  apiRequest(
    "GET",
    `${URL}mobile-users?page=${page}&showOnlyBanned=${banOnly}&searchQuery=${search}`,
    cookie.load("token").token
  );

export const userBan = (date, user) => {
  return apiRequestNoAnswer(
    "PUT",
    `${URL}mobile-users/block?banType=${date}&mobileUserId=${user}`,
    cookie.load("token").token
  );
};

export const userUnblock = user =>
  apiRequestNoAnswer(
    "PUT",
    `${URL}mobile-users/unblock?mobileUserId=${user}`,
    cookie.load("token").token
  );

// Invite
export const sendInvite = user =>
  apiRequestNoAnswerWithBody(
    "POST",
    `${URL}invite`,
    user,
    cookie.load("token").token
  );

export const getInvite = token =>
  apiRequest("GET", `${URL}invite/public/${token}`);

// Product
export const getProductList = (page = 0, status) =>
  apiRequest(
    "GET",
    `${URL}productSelection/list?page=${page}&${status}`,
    cookie.load("token").token
  );

export const createProductSelection = async data => {
  return await apiRequestWithBody(
    "POST",
    `${URL}productSelection`,
    data,
    cookie.load("token").token
  );
};

export const deleteProductSelection = id =>
  apiRequestNoAnswer(
    "DELETE",
    `${URL}productSelection/${id}`,
    cookie.load("token").token
  );

export const getProductSelection = id =>
  apiRequest("GET", `${URL}productSelection/${id}`, cookie.load("token").token);

export const createProductSelectionItem = async (id, data) => {
  return await apiRequestWithBody(
    "POST",
    `${URL}productSelection/item?parentId=${id}`,
    data,
    cookie.load("token").token
  );
};

export const updateProductSelection = async (id, data) => {
  return await apiRequestWithBody(
    "PUT",
    `${URL}productSelection/${id}`,
    data,
    cookie.load("token").token
  );
};

export const deleteProductSelectionItem = id =>
  apiRequest(
    "DELETE",
    `${URL}productSelection/item/${id}`,
    cookie.load("token").token
  );

export const publishProductSelection = async (id, status) => {
  return await apiRequest(
    "PUT",
    `${URL}productSelection/changeStatus/${id}?status=${status}`,
    cookie.load("token").token
  );
};

export const updateProductSelectionItem = async (id, data) => {
  return await apiRequestWithBody(
    "PUT",
    `${URL}productSelection/item/${id}`,
    data,
    cookie.load("token").token
  );
};

// Podcast
export const getAllPodcast = () =>
  apiRequest("GET", `${URL}podcasts`, cookie.load("token").token);

export const createPodcast = async data => {
  return await apiRequestWithBody(
    "POST",
    `${URL}podcasts`,
    data,
    cookie.load("token").token
  );
};

export const updatePodcast = async (id, data) => {
  return await apiRequestWithBody(
    "PUT",
    `${URL}podcasts/${id}`,
    data,
    cookie.load("token").token
  );
};

export const deletePodcast = id =>
  apiRequestNoAnswer(
    "DELETE",
    `${URL}podcasts/${id}`,
    cookie.load("token").token
  );

// Push
export const sendPush = async data => {
  return await apiRequestNoAnswerWithBody(
    "POST",
    `${URL}push/send`,
    data,
    cookie.load("token").token
  );
};

export const sendPushToWhiteList = async data => {
  return await apiRequestNoAnswerWithBody(
    "POST",
    `${URL}push/sendWhiteList`,
    data,
    cookie.load("token").token
  );
};

export const getPushLogs = async () => {
  return await apiRequest(
    "GET",
    `${URL}push/getPushLog`,
    cookie.load("token").token
  );
}

//Competition
export const getCompetitionList = (page, status) =>
  apiRequest(
    "GET",
    `${URL}competition/list?page=${page}&${status}`,
    cookie.load("token").token
  );

export const getCompetition = id =>
  apiRequest("GET", `${URL}competition/${id}`, cookie.load("token").token);

export const createCompetitionItem = async (id, data) => {
  return await apiRequestWithBody(
    "POST",
    `${URL}competition/item?competitionId=${id}`,
    data,
    cookie.load("token").token
  );
};

export const updateCompetitionItem = async (id, data) => {
  return await apiRequestWithBody(
    "PUT",
    `${URL}competition/item/${id}`,
    data,
    cookie.load("token").token
  );
};

export const deleteCompetitionItem = async id => {
  return await apiRequest(
    "DELETE",
    `${URL}competition/item/${id}`,
    cookie.load("token").token
  );
};

export const createEvent = async data => {
  return await apiRequestWithBody(
    "POST",
    `${URL}liveStream`,
    data,
    cookie.load("token").token
  );
};

export const updateEvent = async (id,data) => {
  return await apiRequestWithBody(
    "PUT",
    `${URL}liveStream?liveStreamId=${id}`,
    data,
    cookie.load("token").token
  );
};

export const addCompetition = async data => {
  return await apiRequestWithBody(
    "POST",
    `${URL}competition`,
    data,
    cookie.load("token").token
  );
};

export const editCompetitionById = async (id, data) => {
  return await apiRequestWithBody(
    "PUT",
    `${URL}competition/${id}`,
    data,
    cookie.load("token").token
  );
};

export const updateConditions = async (id, data) => {
  return await apiRequestWithBody(
    "PUT",
    `${URL}competition/conditions/?competitionId=${id}`,
    data,
    cookie.load("token").token
  );
};

export const userWinn = async (competitionItemId, competitorId) => {
  return await apiRequest(
    "POST",
    `${URL}competition/item/winner?competitionItemId=${competitionItemId}&competitorId=${competitorId}`,
    cookie.load("token").token
  );
};

export const userWinnDelete = async competitionItemId => {
  return await apiRequest(
    "DELETE",
    `${URL}competition/item/winner?competitionItemId=${competitionItemId}`,
    cookie.load("token").token
  );
};

export const countСompetition = async competitionId => {
  return await apiRequest(
    "GET",
    `${URL}competition/competitor/count?competitionId=${competitionId}`,
    cookie.load("token").token
  );
};

export const getCompetitors = async (competitionId, count, start) => {
  return await apiRequest(
    "GET",
    `${URL}competition/competitor/list?competitionId=${competitionId}&count=${count}&start=${start}`,
    cookie.load("token").token
  );
};

export const editStatusCompetitors = async (id, status) => {
  return await apiRequest(
    "PUT",
    `${URL}competition/changeStatus?id=${id}&status=${status}`,
    cookie.load("token").token
  );
};

export const updateLiveStreamStatus = async (id, status) => {
  return await apiRequest(
    "PUT",
    `${URL}liveStream/status?liveStreamId=${id}&status=${status}`,
    cookie.load("token").token
  );
};
