import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { UpdateContext } from '../../content/context';
import { deletePodcast } from '../../utils/api';

import Image from '../Image';
import { DoubleText } from '../../atoms/DoubleText';
import Modal from '../Modal';
import UpdatePodcast from '../UpdateContent/Podcast';
import SubmitPush from '../AddContent/SubmitPush';

import { Menu } from '../../content/Icons';
import { getData } from '../../utils/functions';

export class PodcastRow extends Component {
  static contextType = UpdateContext;
  constructor(props) {
    super(props);
    this.state = {
      iisShowModal: false,
      isShowPush: false
    };
  }

  render() {
    return (
      <div>
        <div className='tableRow'>
          <div>
            <Image
              styleImg={{
                borderRadius: 4,
                objectFit: 'cover'
              }}
              img={this.props.value.imageUrl}
              width={48}
              height={48}
            />
          </div>

          <div className='secondary' style={{ width: 'calc(50% - 48px)' }}>
            <DoubleText
              text={this.props.value.title}
              desc={`Выпуск #${this.props.value.number}`}
            />
          </div>

          <div className='secondary' style={{ width: '20%' }}>
            <DoubleText
              text={this.props.value.authorFullName}
              desc={getData(this.props.value.publicationTime, true)}
            />
          </div>

          <div className='secondary' style={{ width: '15%' }}>
            <span>{this.props.value.viewsCount}</span>
          </div>

          <div className='secondary' style={{ width: 'calc(15% - 80px)' }}>
            <div className='pushIndicator' data-push-status={this.props.value.pushStatus}>
              {this.props.value.pushStatus != null ? this.props.value.pushSuccessCount : ''}
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Modal isShowModal={this.state.isShowModal}>
              {
                <UpdatePodcast
                  value={this.props.value}
                  hideModal={() => {
                    this.setState({ isShowModal: false });
                  }}
                />
              }
            </Modal>
            <Modal isShowModal={this.state.isShowPush}>
              {
                <SubmitPush
                  value={{
                    postId: this.props.value.id,
                    topic: 'PODCASTS'
                  }}
                  hideModal={() => {
                    this.setState({ isShowPush: false });
                  }}
                />
              }
            </Modal>

            <DropdownButton
              alignRight
              title={<Menu />}
              id='dropdown-menu-align-right'
            >
              <Dropdown.Item
                onClick={() => {
                  this.setState({ isShowModal: true });
                }}
              >
                Редактировать
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ isShowPush: true });
                }}
              >
                Отправить пуш
              </Dropdown.Item>
              <Dropdown.Item
                onClick={async () => {
                  await deletePodcast(this.props.value.id);
                  this.context.setState();
                }}
              >
                Удалить
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
    );
  }
}
