import React from 'react';
import { UpdateContext } from '../../content/context';
import MenuBar from '../../components/MenuBar';
import Line from '../../components/Line';
import { CustomLoader } from '../../atoms/Loader';
import { PodcastRow } from '../../components/Tables/PodcastRow';
import { getAllPodcast } from '../../utils/api';
import { NoData } from '../../components/Gag';

export default class PodcastsPage extends React.Component {
  static contextType = UpdateContext;
  constructor(props) {
    super(props);
    this.state = {
      podcasts: [],
      isUpdate: false,
      isFetch: false
    };
    this.setUpdate = this.setUpdate.bind(this);
  }

  async getData() {
    this.setState({
      isFetch: true
    });
    let data = await getAllPodcast();
    this.setState({
      podcasts: data.data,
      isFetch: false
    });
  }

  componentDidMount() {
    this.getData();
  }

  setUpdate() {
    this.setState({
      isUpdate: !this.state.isUpdate
    });
  }

  componentDidUpdate() {
    if (this.state.isUpdate) {
      this.getData();
      this.setState({
        isUpdate: !this.state.isUpdate
      });
    }
  }

  renderRows() {
    if (this.state.podcasts.length === 0) {
      return (<NoData doing={'добавили'} what={'ни одного подкаста'}/>)
    }

    return this.state.podcasts
      .map(
        item =>
          <PodcastRow
            key={item.id}
            value={item}
          />
      )
  }

  render() {
    return (
      <div>
        <UpdateContext.Provider
          value={{
            isUpdate: this.state.isUpdate,
            setState: () => {
              this.setState({
                isUpdate: !this.state.isUpdate
              });
            }
          }}
        >
          <div className='MenuBar wrapper'>
            <MenuBar
              name={'Подкасты'}
              description={'Загружайте подкасты'}
              forma={'Podcast'}
            />
          </div>
          <Line />

          <div className="intent">
            <div className="tableHead">
              <div style={{ width: '50%' }}>
                <p>Подкаст</p>
              </div>
              <div className="secondary" style={{ width: '20%' }}>
                <p>Опубликовано</p>
              </div>
              <div className="secondary" style={{ width: '15%' }}>
                <p>Прослушивания</p>
              </div>
              <div className="secondary" style={{ width: '15%' }}>
                <p>Пуш</p>
              </div>
            </div>
            { this.state.isFetch ? <CustomLoader/> : this.renderRows() }
          </div>
        </UpdateContext.Provider>
      </div>
    );
  }
}
