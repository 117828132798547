import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { UpdateContext } from '../../content/context';

import { DoubleText } from '../../atoms/DoubleText';
import Modal from '../Modal';
import SubmitPush from '../AddContent/SubmitPush';
import SubmitPushToWhiteList from '../AddContent/SubmitPushToWhiteList';

import { Menu } from '../../content/Icons';
import { getData } from '../../utils/functions';

export class WylsaPostRow extends Component {
  static contextType = UpdateContext;
  constructor(props) {
    super(props);
    this.state = {
      isShowModal: {
        submitPush: false,
        submitPushToWhiteList: false
      }
    };
  }

  render() {
    return (
      <div>
        <div className='tableRow'>
          <div style={{ width: '55%' }}>
            <span>{this.props.value.postTitle}</span>
          </div>

          <div className='secondary' style={{ width: '20%' }}>
            <DoubleText
              text={this.props.value.postAuthor.displayName}
              desc={getData(this.props.value.postDateGmt)}
            />
          </div>

          <div className='secondary' style={{ width: '10%' }}>
            <span>{this.props.value.views}</span>
          </div>

          <div className='secondary' style={{ width: '10%' }}>
            <div className='pushIndicator' data-push-status={this.props.value.pushStatus}>
              {this.props.value.pushStatus != null ? this.props.value.pushSuccessCount : ''}
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Modal isShowModal={this.state.isShowModal.submitPush}>
              {
                <SubmitPush
                  value={{
                    postId: this.props.value.wpPostId,
                    topic: 'WYLSA_SITE_POST'
                  }}
                  hideModal={() => {
                    this.setState({
                      isShowModal: {
                        submitPush: false,
                        submitPushToWhiteList: false,
                      }
                    })
                  }}
                />
              }
            </Modal>

            {/* Modal for Sending Push to White List */}
            <Modal isShowModal={this.state.isShowModal.submitPushToWhiteList}>
              {
                <SubmitPushToWhiteList
                  value={{
                    postId: this.props.value.wpPostId,
                    topic: 'WYLSA_SITE_POST'
                  }}
                  hideModal={() => {
                    this.setState({
                      isShowModal: {
                        submitPush: false,
                        submitPushToWhiteList: false,
                      }
                    })
                  }}
                />
              }
            </Modal>

            <DropdownButton
              alignRight
              title={<Menu />}
              id='dropdown-menu-align-right'
            >
              <Dropdown.Item
                onClick={() => {
                  this.setState({
                    isShowModal: {
                      submitPush: true,
                      submitPushToWhiteList: false,
                    }
                  })
                }}
              >
                Отправить пуш
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  this.setState({
                    isShowModal: {
                      submitPush: false,
                      submitPushToWhiteList: true,
                    }
                  })
                }}
              >
                [DEV] – Push Debugger
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
    );
  }
}
