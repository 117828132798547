import React from 'react';
import { CustomLoader } from '../../atoms/Loader';
import { EventRow } from '../../components/Tables/EventRow';
import { getLiveStreamList, updateLiveStreamStatus } from '../../utils/api';
import { UpdateContext } from '../../content/context';
import { NoData } from '../../components/Gag';
import Pagination from '../../components/Pagination';

class EventPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetch: false,
      posts: [],
      activePage: this.getActivePage() - 1,
      pageSize: this.getActivePage()
    };
  }

  sourceUrl(post) {
    switch (post.source) {
      case 'YOUTUBE':
        return post.youtubeUrl;
      case 'VK':
        return post.vkUrl;
      case 'VIMEO':
        return post.vimeoUrl;
      default:
        return '';
    }
  }

  statusTitle(status) {
    switch (status) {
      case 'DRAFT':
        return 'Черновик';
      case 'PLANNED':
        return 'Опубликована';
      case 'LIVE':
        return 'Live';
      case 'FINISHED':
        return 'Завершена';
      case 'ARCHIVE':
        return 'Архив';
      default:
        return '';
    }
  }

  nextStatus(status) {
    switch (status) {
      case 'DRAFT':
        return {
          title: 'Опубликовать',
          value: 'PLANNED'
        };
      case 'PLANNED':
        return {
          title: 'Запустить',
          value: 'LIVE'
        };
      case 'LIVE':
        return {
          title: 'Завершить',
          value: 'FINISHED'
        };
      case 'FINISHED':
        return {
          title: 'Архивировать',
          value: 'ARCHIVE'
        };
      case 'ARCHIVE':
      default:
        return {
          title: 'NONE',
          value: 'NONE'
        };
    }
  }

  async getData() {
    this.setState({
      isFetch: true
    });
    let data = await getLiveStreamList(this.state.activePage);
    if (data.error) {
      this.setState({
        posts: [],
        isFetch: false
      });
    } else {
      this.setState({
        isFetch: false,
        posts: data.data.map(post => {
          return {
            ...post,
            statusTitle: this.statusTitle(post.status),
            sourceUrl: this.sourceUrl(post),
            nextStatus: this.nextStatus(post.status)
          }
        }),
        pageSize: !data.attributes.totalCount
          ? this.state.pageSize
          : Math.ceil(data.attributes.totalCount / 10)
      });
    }
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    if (this.context.isUpdate) {
      this.getData();
      this.context.setState();
    }
  }

  getActivePage = () => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var vars = url.searchParams.get('page');
    return vars;
  };

  async updateStatus(id, nextStatus) {
    let data = await updateLiveStreamStatus(id, nextStatus);
    if (data.error) {
      this.setState({ isError: true });
    } else {
      const updatedPosts = this.state.posts.map(post => {
        if (post.id !== id) {
          return post;
        }
        return {
          ...post,
          status: nextStatus,
          statusTitle: this.statusTitle(nextStatus),
          nextStatus: this.nextStatus(nextStatus)
        }
      });
      this.setState(
        {
          posts: [ ...updatedPosts ]
        }
      );
    }
  }

  renderRows() {
    if (this.state.posts.length === 0) {
      return (<NoData doing={'создали'} what={'ни одной трансляции'}/>)
    }

    return this.state.posts
      .map(
        item =>
          <EventRow
            key={item.id}
            value={item}
            updateStatus={() => this.updateStatus(item.id, item.nextStatus.value)}
          />
      )
  }

  render() {
    if (this.state.isFetch) {
      return <CustomLoader/>;
    }

    return (
      <div className="intent">
        <div className="tableHead">
          <div style={{ width: '40%' }}>
            <p>Трансляция</p>
          </div>
          <div className="secondary" style={{ width: '15%' }}>
            <p>Начало</p>
          </div>
          <div className="secondary" style={{ width: '10%' }}>
            <p>Источник</p>
          </div>
          <div className="secondary" style={{ width: '10%' }}>
            <p>Статус</p>
          </div>
          <div className="secondary" style={{ width: '10%' }}>
            <p>Просмотры</p>
          </div>
          <div className='secondary' style={{ width: 'calc(10% - 80px)' }}>
            <p>Пуш</p>
          </div>
        </div>
        {
          this.renderRows()
        }

        <Pagination
          link={"/feed/events?page="}
          activePage={this.state.activePage}
          pageSize={this.state.pageSize}
          setStatePage={page =>
            this.setState({ activePage: page }, () => this.getData())
          }
        />
      </div>
    );
  }
}

export default EventPage;
EventPage.contextType = UpdateContext;
