import React from 'react';

import { ButtonLoader, CustomLoader } from '../../atoms/Loader';
import { Input, InputRadio } from '../../atoms/Input';

import { UploadWithPreview } from '../../components/Upload';

import {
  getParameterFromUrl,
  convertToDay,
  convertToTime
} from '../../utils/functions';
import { URL_FILE } from '../../content/const';

import {
  addImage,
  createEvent,
  getLiveStreamById,
  updateEvent
} from '../../utils/api';

var moment = require('moment');

export default class AddEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: getParameterFromUrl('id'),
      file: null,
      imageUrl: '',
      liveStartTime: '',
      liveStartDay: '',
      description: '',
      title: '',
      isFetchingButton: false,
      isFetching: false,
      imageParam: null,
      vimeoUrl: '',
      youtubeUrl: '',
      vkUrl: '',
      vimeoHlsUrl: '',
      sourceStream: '',
      sponsorText: ''
    };
  }

  onSetImage = async file => {
    this.setState({isFetchingButton: true });

    const image = await addImage(file);

    if (image.error) {
      this.setState({ error: true, isFetchingButton: false });
      throw new Error(image.message);
    }

    const imageUrl = URL_FILE + image.fileName;

    this.setState({
      file: file,
      isFetchingButton: false,
      imageUrl: imageUrl
    });
  };

  onChangeSourceStream = (event) => {
    const sourceStream = event.target.value;
    this.setState({ sourceStream: sourceStream })
  };

  async componentDidMount() {
    if (!!this.state.id) {
      this.setState({ isFetching: true });
      const data = await getLiveStreamById(this.state.id);
      if (data.error) {
        this.setState({ error: true, isFetching: false });
      } else {
        this.setState({
          imageUrl: data.imageUrl,
          description: data.description,
          title: data.title,
          youtubeUrl: data.youtubeUrl,
          vimeoUrl: data.vimeoUrl,
          vkUrl: data.vkUrl,
          vimeoHlsUrl: data.vimeoHlsUrl,
          liveStartTime: convertToTime(data.liveStart),
          liveStartDay: convertToDay(data.liveStart),
          isFetching: false,
          sourceStream: data.source,
          sponsorText: data.sponsorText
        })
      }
    }
  }

  mapToRequest = (state) => {
    return {
      imageUrl: state.imageUrl,
      title: state.title,
      description: state.description,
      youtubeUrl: state.youtubeUrl,
      vkUrl: state.vkUrl,
      vimeoUrl: state.vimeoUrl,
      vimeoHlsUrl: state.vimeoHlsUrl,
      source: state.sourceStream,
      sponsorText: state.sponsorText,
      liveStart: moment(
        this.state.liveStartDay + ' ' + this.state.liveStartTime
      ).unix()
    }
  };

  createEvent = async () => {
    if (!this.submitEnabled()) {
      return;
    }
    this.setState({ isFetchingButton: true });

    const createRequest = this.mapToRequest(this.state);

    const data = await createEvent(createRequest);

    if (data.error) {
      this.setState({ error: true, isFetchingButton: false });
    } else {
      this.setState({
        id: data.id,
        isFetchingButton: false
      }, () => {
        document.location.href = `/feed/events?page=1`;
      });
    }
  };

  updateEvent = async () => {
    if (!this.submitEnabled()) {
      return;
    }

    this.setState({ isFetchingButton: true });

    const updateRequest = this.mapToRequest(this.state);

    const data = await updateEvent(this.state.id, updateRequest);

    if (data.error) {
      this.setState({ error: true, isFetchingButton: false });
    } else {
      this.setState({
        id: data.id,
        isFetchingButton: false
      }, () => {
        document.location.href = `/feed/events?page=1`;
      });
    }
  };

  submitEnabled = () => {
    const { title, description, liveStartDay, liveStartTime, imageUrl, file, youtubeUrl, sourceStream } = this.state

    return (
      (!!file || !!imageUrl)
      && !!title
      && !!description
      && !!liveStartDay
      && !!liveStartTime
      && !!youtubeUrl
      && !!sourceStream
    )
  };

  render() {
    const isCreateForm = !this.state.id;
    const sourceStream = this.state.sourceStream;

    const title =
      isCreateForm ? 'Создание трансляции' : 'Редактирование трансляции';
    const submitButtonTitle =
      isCreateForm ? 'Создать и продолжить' : 'Сохранить изменения';

    const onSubmit = isCreateForm ? this.createEvent : this.updateEvent;
    if(this.state.isFetching) {
      return (<CustomLoader/>)
    }

    return (
      <div className='fullWidthForm'>
        <div>
          <div className="header">
            <h1>{title}</h1>
          </div>

          <div className="formBlock">
            <h4>О трансляции*</h4>
            <p className="formBlockDescription">
              Название будет видно везде, а краткое описание только в мобильных приложениях.
            </p>

            <Input
              state       = {this.state.title}
              onChange    = {(event) => this.setState({ title: event.target.value })}
              placeholder = {'Название события'}
            />

            <div style={{ paddingTop: 20 }}>
              <textarea
                placeholder={'Краткое описание'}
                className="input100"
                value={this.state.description}
                onChange={event => {
                  this.setState({ description: event.target.value });
                }}
              />
            </div>

            <div style={{ paddingTop: 20 }}>
              <textarea
                placeholder={'Спонсор'}
                className="input100"
                value={this.state.sponsorText}
                onChange={event => {
                  this.setState({ sponsorText: event.target.value });
                }}
              />
            </div>
          </div>

          <div className="formBlock">
            <div className='inputsGrid'>
              <div>
                <h4>Загрузите обложку*</h4>
                <p className="formBlockDescription">
                  Будет видна только в приложении для Apple TV (3840×2160).
                </p>

                <div>
                  <UploadWithPreview
                    value={{
                      imageUrl: this.state.imageUrl,
                      setFile: this.onSetImage,
                      deleteFile: () => {
                        this.setState({ file: null, imageUrl: '' });
                      }
                    }}
                    text={'Выбрать фото'}
                    type={'image/*'}
                  />
                </div>
              </div>

              <div>
                <h4>Планируемое время начала трансляции*</h4>
                <p className="formBlockDescription">
                  Это время будет видно пользователям, но трансляция не&nbsp;запустится автоматически.
                </p>

                <div
                  style = {{
                    display: 'grid',
                    gridTemplateColumns: '56% 38%',
                    gridColumnGap: 16,
                  }}
                >
                  <Input
                    state       = {this.state.liveStartDay}
                    onChange    = {(event) => this.setState({ liveStartDay: event.target.value })}
                    type        = {'date'}
                    placeholder = {'DD.MM.YYYY'}
                  />

                  <Input
                    state       = {this.state.liveStartTime}
                    onChange    = {(event) => this.setState({ liveStartTime: event.target.value })}
                    type        = {'time'}
                    placeholder = {'21:00'}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='formBlock'>
            <h4 style={{marginBottom: 24}}>Источники</h4>

            <div className='inputsGrid'>
              <Input
                state       = {this.state.vimeoUrl}
                onChange    = {(event) => this.setState({ vimeoUrl: event.target.value })}
                placeholder = {'XXXXXXXXX'}
                header      = {'Vimeo Live ID'}
                description = {'Здесь нужно вставить ID трансляции'}
              />

              <Input
                state       = {this.state.vimeoHlsUrl}
                onChange    = {(event) => this.setState({ vimeoHlsUrl: event.target.value })}
                placeholder = {'https://'}
                header      = {'Vimeo HLS'}
                description = {'Ссылку можно взять в админке Vimeo. Будет использоваться для проигрывания записи трансляции.'}
              />
            </div>

            <div className='inputsGrid'>
              <Input
                state       = {this.state.vkUrl}
                onChange    = {(event) => this.setState({ vkUrl: event.target.value })}
                placeholder = {'https://'}
                header      = {'VK HLS'}
              />

              <Input
                state       = {this.state.youtubeUrl}
                onChange    = {(event) => this.setState({ youtubeUrl: event.target.value })}
                placeholder = {'https://'}
                header      = {'YouTube*'}
              />
            </div>

            <div className='inputsGrid'>
              <div>
                <span className='inputHeader'>Активный источник*</span>

                <InputRadio
                  id       = {'vimeo'}
                  checked  = {sourceStream === 'VIMEO'}
                  onChange = {this.onChangeSourceStream}
                  value    = {'VIMEO'}
                  label    = {'Vimeo'}
                />

                <InputRadio
                  id       = {'youtube'}
                  checked  = {sourceStream === 'YOUTUBE'}
                  onChange = {this.onChangeSourceStream}
                  value    = {'YOUTUBE'}
                  label    = {'YouTube'}
                />

                <InputRadio
                  id       = {'vk'}
                  checked  = {sourceStream === 'VK'}
                  onChange = {this.onChangeSourceStream}
                  value    = {'VK'}
                  label    = {'VK'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='submitWrapper'>
          <div>
            <p className='button_description'>
            Трансляция не будет опубликована
            </p>
            <div onClick={onSubmit} >
              <p className={`large_button ${this.submitEnabled() ? 'active' : 'disable'}`}>
                {this.state.isFetchingButton ? (<ButtonLoader/>) : (submitButtonTitle)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
