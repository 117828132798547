import React from "react";

import "./AddVideo.css"
import {addVideoByURL, addVideoFromS3} from "../../utils/api";
import {isEmpty} from "../../utils/functions";
import {UpdateContext} from "../../content/context";


const initYoutubeDate = {
    url: ""
};

const initS3Data = {
    s3BucketUrl: "",
    title: "",
    description: "",
    imageUrl: "",
    imageUrlMaxRes: ""
};

class AddVideo extends React.Component {
    static contextType = UpdateContext;

    constructor(props) {
        super(props);
        this.state = {
            url: "",
            sourceType: "youtube",
            youtubeData: initYoutubeDate,
            s3BucketData: initS3Data,
            errorURL: false,
            isExit: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    isActive() {
        if (this.state.sourceType === "youtube") {
            return this.state.youtubeData.url.length !== 0;
        } else {
            return this.state.s3BucketData.s3BucketUrl.length !== 0
                && this.state.s3BucketData.title.length !== 0
                && this.state.s3BucketData.description.length !== 0
                && this.state.s3BucketData.imageUrl.length !== 0
                && this.state.s3BucketData.imageUrlMaxRes.length !== 0;
        }

    }

    handleChange({stateField, key, value}) {
        this.setState({
            [stateField]: {...this.state[stateField], [key]: value}
        });
    }

    handleSubmit = async event => {
        event.preventDefault();
        if (this.state.isExit) {
            this.setState({isExit: false});
            this.props.hideModal();
        } else {
            let result
            if(this.state.sourceType === "youtube") {
                 result = await addVideoByURL(this.state.youtubeData.url);
            } else {
                result = await addVideoFromS3(this.state.s3BucketData)
            }
            if (result.error) {
                alert(result.message);
            } else {
                this.setState({youtubeDate: initYoutubeDate});
                if (!isEmpty(result)) {
                    this.context.setState();
                }
            }
            this.props.hideModal();
        }
    };

    onChange(stateField, key) {
        return (e) => this.setState({[stateField]: {...this.state[stateField], [key]: e.target.value}});
    }

    render() {
        if (this.state.sourceType === "youtube") {
            return this.renderAddYoutubeForm();
        } else {
            return this.renderAddS3Form()
        }
    }

    renderAddS3Form() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <h2>Поделитесь видео</h2>

                    <h4 style={{paddingTop: 16}}>Источник</h4>

                    {this.renderTypeToggle()}

                    <div className="modalWindowSection">
                        <div className="inputWrapper">
                            <input
                                type="text"
                                placeholder={"Название"}
                                className={"input100"}
                                value={this.state.s3BucketData.title}
                                onChange={this.onChange("s3BucketData", "title")}
                            />
                        </div>
                        <div className="inputWrapper">
                            <textarea
                                type="text"
                                placeholder={"Описание"}
                                className={"input100"}
                                value={this.state.s3BucketData.description}
                                onChange={this.onChange("s3BucketData", "description")}
                            />
                        </div>
                        <div className="inputWrapper">
                            <input
                                type="text"
                                placeholder={"Ссылка на видео"}
                                className={this.state.errorURL ? "input100 error" : "input100"}
                                value={this.state.s3BucketData.s3BucketUrl}
                                onChange={this.onChange("s3BucketData", "s3BucketUrl")}
                            />
                        </div>
                        <div className="inputWrapper">
                            <input
                                type="text"
                                placeholder={"Ссылка на обложку (min)"}
                                className={"input100"}
                                value={this.state.s3BucketData.imageUrl}
                                onChange={this.onChange("s3BucketData", "imageUrl")}
                            />
                        </div>
                        <div className="inputWrapper">
                            <input
                                type="text"
                                placeholder={"Ссылка на обложку (max)"}
                                className={"input100"}
                                value={this.state.s3BucketData.imageUrlMaxRes}
                                onChange={this.onChange("s3BucketData", "imageUrlMaxRes")}
                            />
                        </div>
                    </div>

                    {this.renderButtons()}
                </form>
            </div>
        );
    }

    renderAddYoutubeForm() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <h2>Поделитесь видео</h2>

                    <h4 style={{paddingTop: 16}}>Источник</h4>

                    {this.renderTypeToggle()}

                    <div className="modalWindowSection">
                        <div className="inputWrapper">
                            <input
                                type="text"
                                placeholder={"Ссылка на YouTube видео"}
                                className={this.state.errorURL ? "input100 error" : "input100"}
                                value={this.state.youtubeData.url}
                                onChange={this.onChange("youtubeData", "url")}
                            />
                        </div>
                    </div>

                    {this.renderButtons()}
                </form>
            </div>
        )
    }

    renderButtons() {
        return <div className="buttonsWrapper">
            <div style={{flex: 1}}/>
            <button
                className="large_button simple"
                onClick={() => {
                    this.props.hideModal();
                    this.setState({isExit: true});
                }}
            >
                Отмена
            </button>
            <input
                className={
                    this.isActive() ? "large_button active" : "large_button disable"
                }
                type="submit"
                value="Добавить"
            />
        </div>;
    }

    renderTypeToggle() {
        return <div className="formBlock" style={{paddingTop: 12}}>
            <div style={{display: "flex"}}>
                <div
                    className={
                        this.state.sourceType === "youtube" ? "sourceTypeTab active" : "sourceTypeTab"
                    }
                    onClick={() => {
                        this.setState({sourceType: "youtube"});
                    }}
                >
                    YouTube
                </div>
                <div
                    className={
                        this.state.sourceType === "s3Bucket" ? "sourceTypeTab active" : "sourceTypeTab"
                    }
                    onClick={() => {
                        this.setState({sourceType: "s3Bucket"});
                    }}
                >
                    Yandex.Cloud
                </div>
            </div>
        </div>;
    }
}

export default AddVideo;
