import React from "react"
import { UpdateContext } from "../content/context"
import cookie from "react-cookies"

import { getPushLogs }  from "../utils/api"
import { CustomLoader } from "../atoms/Loader"
import { ConsoleRow }   from "../components/Tables/ConsoleRow"

import "../stylesheets/pages/PushLogsPage.css"

export default class PushLogsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pushLogs: null
    }
  }

  async getData() {
    this.setState({
      isFetch: false
    })

    let data = await getPushLogs()

    if (data.error) {
      console.log('Error')
      console.log(data)
    } else {
      console.log('Success')
      this.setState({
        pushLogs: data
      })
    }
  }

  componentDidMount() {
    this.getData()
  }

  render() {
    if (!this.state.pushLogs) {
      return <CustomLoader />
    }

    let pushLogs = this.state.pushLogs
    let pushLogsCutted = pushLogs.slice(Math.max(pushLogs.length - 1000, 0))

    return(
      <div className="pushLogsPage">
        { pushLogsCutted.reverse().map((item, i) => <ConsoleRow value={item} key={i} />) }
      </div>
    )
  }
}

PushLogsPage.contextType = UpdateContext;
