import React, { Component } from "react"
import { UpdateContext } from "../../content/context"
import { dataString }  from "../../utils/functions"

export class ConsoleRow extends Component {
  static contextType = UpdateContext;
  constructor(props) {
    super(props);
  }

  render() {
    let formattedDate = new Date(this.props.value.createTime)

    return (
      <div>
        <span className="id">
          { this.props.value.pushLogId }
        </span>

        <span className="time">
          { formattedDate.toLocaleString('ru-RU') }
        </span>

        <span
          className={ this.props.value.logText.includes('Successfully') ? 'success data' : 'data' }
        >
          { this.props.value.logText}
        </span>
      </div>
    );
  }
}
