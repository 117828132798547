import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { UpdateContext } from '../../content/context';
import Image from '../Image';

import { DoubleText } from '../../atoms/DoubleText';
import { LabelBlue } from '../../atoms/Labels';

import Modal from '../Modal';
import SubmitPush from '../AddContent/SubmitPush';

import { Menu } from '../../content/Icons';
import {
  dataString,
  getIconBySource
} from '../../utils/functions';

export class EventRow extends Component {
  static contextType = UpdateContext;

  constructor(props) {
    super(props);
    this.state = { isShowModal: false, isError: false };
  }

  render() {
    const liveStart = this.props.value.liveStart;
    const updateStatus = this.props.updateStatus;

    return (
      <div>
        <div className="tableRow">
          <div style={{ width: '40%' }}>
            <Image
              styleImg={{
                borderRadius: 4,
                objectFit: 'cover'
              }}
              img={this.props.value.imageUrl}
              width={85}
              height={48}
            />

            <div className="secondary" style={{ width: 'calc(100% - 85px)' }}>
              <DoubleText
                text={this.props.value.title}
                desc={this.props.value.description}
              />
            </div>
          </div>

          <div className="secondary" style={{ width: '15%' }}>
            <span>{liveStart ? dataString(liveStart, true) : ''}</span>
          </div>

          <div className="secondary" style={{ width: '10%' }}>
            <a
              href={this.props.value.sourceUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: 18,
                fontSize: 18,
                textAlign: 'center'
              }}
            >
              {getIconBySource(this.props.value.source)}
            </a>
          </div>

          <div className="secondary" style={{ width: '10%' }}>
            <div>
              <LabelBlue text={this.props.value.statusTitle}/>
            </div>
          </div>

          <div className='secondary' style={{ width: '10%' }}>
            <span>{this.props.value.viewsCount}</span>
          </div>


          <div className='secondary' style={{ width: 'calc(15% - 80px)' }}>
            <div className='pushIndicator' data-push-status={this.props.value.pushStatus}>
              {this.props.value.pushStatus != null ? this.props.value.pushSuccessCount : ''}
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Modal isShowModal={this.state.isShowModal}>
              {
                <SubmitPush
                  value={{
                    postId: this.props.value.id,
                    topic: 'LIVE_STREAMS'
                  }}
                  hideModal={() => {
                    this.setState({ isShowModal: false });
                  }}
                />
              }
            </Modal>

            <DropdownButton
              style={{ width: 80, textAlign: 'center' }}
              alignRight
              title={<Menu/>}
              id="dropdown-menu-align-right"
            >
              <Dropdown.Item onClick={() => {
                document.location.href = `/event?id=${this.props.value.id}`;
              }}>
                Редактировать
              </Dropdown.Item>
              {this.props.value.nextStatus.value !== 'NONE' &&
              <Dropdown.Item onClick={updateStatus}>
                {this.props.value.nextStatus.title}

                {/*
                  Меняется в зависимости от текущего статуса.
                  Опубликовать → Запустить → Завершить → Архивировать
                */}
              </Dropdown.Item>
              }

              <Dropdown.Item
                onClick={() => {
                  this.setState({
                    isShowModal: {
                      submitPush: true,
                      submitPushToWhiteList: false
                    }
                  })
                }}
              >
                Отправить пуш
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
    );
  }
}
